import { gql } from "@apollo/client";

// export const GET_SETTINGS = gql`
//   query InstasportSettings {
//     settings: instasportSettings {
//       country {
//         id
//         title
//         alpha2
//       }
//     }
//   }
// `;

export const GET_COUNTRIES_LOCALIZATION = gql`
  query Countries {
    countries {
      id
      slug
      title
      alpha2
      phoneCode
      longitude
      latitude
    }
  }
`;

// export const GET_SETTINGS = gql`
//   query Country($slug: String!) {
//     country(slug: $slug) {
//       id
//       title
//       alpha2
//       # languages
//       # languageDefault
//       # phoneCode
//     }
//   }
// `;
